@import '../../style/colors';

.table-header-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: transparent;
  outline: none;
  border: none;
  font-weight: lighter;
  text-transform: math-auto;
  color: $primary-30;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
  &:active {
    outline: none;
  }
}
