.MuiIconButton-sizeSmall {
  width: 16px;
  height: 16px;
}

.ac-select__tooltip-icon {
  font-size: 14px;
}

.apply-button--container {
  background: #fff;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  box-sizing: content-box;
  text-transform: none;
  button {
    font-size: 16px;
    font-weight: 600;
  }
}

.selector-checkbox {
  .select-block {
    width: 200px;

    .MuiSelect-select {
      width: 130px;
    }
  }
}

.formContent-select-searchField {
  padding: 0 5px;
}
