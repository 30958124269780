@import '../../../../src/variables.scss';

#clearFiltersButton {
  margin: 0;
  font-size: 15px;
  padding: 6px 0;
  line-height: normal;
  text-transform: none;
  font-family: $font-family;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
}
