.editLanguageForm {
  width: 100%;
  &__input {
    font-size: 12px;
    font-weight: 500;
    width: 100%;

    .formContent-input-inputField {
      & input {
        padding: 6px 8px;
      }
    }
  }
  &__select {
    margin-top: 10px;
    margin-right: 0;
    min-width: 340px;
    & h3 {
      font-size: 12px;
      font-weight: 500;
    }
    .formContent-select-selectField {
      & .MuiSelect-select {
        padding: 6px 8px;
      }
    }
  }
  & .editLanguageFormContainer {
    align-items: stretch;
    flex-direction: column;
  }

  & button {
    margin-top: 18px;
    padding: 5px 16px;
  }
}
