.integraion-profile-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .MuiStack-root.input-headerBlock.formContent-input-fieldTitle {
    display: none;
  }

  div {
    height: 32px;
    width: 100%;
  }

  svg {
    cursor: pointer;
    height: 32px;
    margin-right: 8px;
  }
}

.description {
  h3 {
    margin-top: 12px;
  }
  p {
    font-size: 12px;
    max-width: 500px;
  }
}
