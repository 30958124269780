.iphone-mock-wrapper {
  display: flex;
  justify-content: flex-end;
}

.iphone-mock {
  width: 375px;
  height: 812px;
  position: relative;
  padding-top: 80px;
  background: #322566;
  border-radius: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  border-radius: 1em;

  img.storeLogo {
    margin-bottom: 40px;
    height: 120px;
  }
}
