@import '../variables.scss';

.formContent {
  &-minWIdth {
    min-width: 512px;
  }

  &-select,
  &-input {
    &-selectField,
    &-inputField {
      .MuiInputBase-input,
      .MuiFormControl-input {
        padding: 14px 8px;
        color: $neutral-70;
        line-height: normal;
        display: flex;
        align-items: center;
        margin: 0;
        flex-wrap: nowrap;
        @include typo-title-s;

        &::placeholder {
          color: $neutral-50;
          opacity: 100;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid $neutral-30;
        border-radius: 4px;
      }

      .MuiInputBase-adornedStart {
        padding-left: 8px;

        .MuiInputAdornment-root {
          width: 0;
        }
      }

      & > .MuiSelect-select {
        max-height: 0;
      }
    }

    .select-block__close-btn
      + .formContent-select-selectField
      .MuiInputBase-input {
      padding-left: 28px;
    }

    .select-block {
      position: relative;

      &__close-btn {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        display: flex;
        align-items: center;

        + .formContent-select-selectField {
          .MuiInputBase-input {
            padding-left: 28px;
          }
        }
      }
    }

    .MuiFormHelperText-root {
      margin: 3px 0 0;
      color: $neutral-50;
    }

    .MuiAutocomplete-root {
      label {
        display: flex;
        align-items: center;
        color: $neutral-70;
        line-height: normal;
        @include typo-title-s;
      }

      .MuiInputBase-root {
        padding: 14px 8px;

        input {
          padding: 0;
        }
      }
    }

    &-gradientInput {
      .MuiInputBase-adornedStart {
        .MuiInputAdornment-root {
          width: 32px;
        }

        input {
          padding-left: 0;
        }
      }
    }

    &-fieldTitle > h3 {
      @include typo-title-xs;
      white-space: nowrap;
    }

    &-fieldTitle > button svg {
      width: 16px;
      height: 16px;
    }

    &-uploadButton,
    .MuiButton-root {
      color: $primary-40;
      margin: 8px 0;
      padding: 0;
      line-height: normal;
      text-transform: none;
      justify-content: flex-start;
      @include typo-title-xs;

      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  }

  &-searchInput {
    &-searchField {
      border: 1px dashed $neutral-30;
      border-radius: 24px !important;
      padding-left: 25px !important;
      height: 40px;
      display: flex;
      flex-direction: row-reverse;
      position: relative;

      .MuiSelect-select {
        max-height: 0;
        color: $primary-40 !important;
        position: relative;
        line-height: 17px !important;
        padding-right: 10px !important;
        font-size: 12px !important;
      }

      &-label {
        display: flex;
      }

      &-searchValue input {
        padding: 0 10px;
        height: 32px;
      }

      input {
        display: none;
      }

      .MuiSelect-iconOutlined {
        display: none;
      }

      .MuiInputBase-input,
      .MuiFormControl-input {
        color: $neutral-70;
        line-height: normal;
        @include typo-title-s;

        &::placeholder {
          color: $neutral-50;
          opacity: 100;
        }
      }

      .MuiOutlinedInput {
        &-notchedOutline {
          color: $primary-40;
          border: none;
          position: static;
          padding: 0;
          min-width: min-content;

          legend {
            overflow: visible;
            height: auto;
            min-width: min-content;
            padding: 0;
            width: auto;
          }

          span {
            opacity: 1;
            color: rgba(0, 0, 0, 0.6);
            width: auto;
            padding-right: 0;
            cursor: pointer;
            pointer-events: fill;
            user-select: none;
            font-weight: 600;
          }
        }
      }

      .MuiInputBase-adornedStart {
        padding-left: 8px;

        .MuiInputAdornment-root {
          width: auto;
          padding-right: 8px;
        }
      }
    }
  }

  &-imageSelected {
    padding-top: 2px;
    padding-bottom: 2px;
    display: flex;
    align-items: center;
    width: inherit;

    img {
      margin-right: 4px;
      object-fit: cover;
      width: 32px;
      max-height: 32px;
    }
  }

  &-card {
    &-title > h3 {
      margin-bottom: 12px;
      font-style: normal;
      line-height: normal;
      @include typo-title-base_medium;
    }

    &-description > p {
      margin: 0 0 12px;
      font-style: normal;
      line-height: normal;
      color: $neutral-50;
      font-size: 0.88rem;
      font-weight: 500;
    }
  }

  .MuiDivider-root {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
  }

  .offers-form-sections {
    &.offers-form-sections {
      z-index: 1;
    }
  }
}

.search-open-icon {
  cursor: pointer;
  width: 0.7em !important;
  position: absolute;
  left: 8px;
}

.pointer-cursor {
  cursor: pointer !important;
}
