@import '../../variables.scss';

#backOffice {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  #main {
    padding-left: $sidebarWidth;
    height: 100vh;
    background-color: $backgroundColor;
    color: white;
    transition: padding 1s;
    overflow: hidden;

    &.collapsed {
      padding-left: $sidebarCollapsedWidth;
    }
  }

  .MuiDataGrid-row > div {
    outline: none !important;
    transition: 0.5s width;
  }

  .MuiDataGrid-columnHeader--sortable {
    transition: 0.5s width;
  }
}

#notifications {
  display: flex;
  flex-direction: column-reverse;
  position: absolute;
  right: 20px;
  bottom: 100px;
}

h1 {
  font-weight: 700;
  font-size: 32px;
  margin: unset;
}

h2 {
  font-weight: 700;
  font-size: 24px;
}

h3 {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}

body {
  color: $text;

  .danger {
    color: $error !important;
    fill: $error !important;
  }

  button:disabled {
    opacity: 0.4;

    &.MuiButton-outlinedPrimary {
      color: $primary;
    }
  }

  .primary {
    color: $primary;
  }

  a {
    text-decoration: none;
  }
}

.text {
  font-weight: 400;
  font-size: 16px;
}

.smallText {
  font-weight: 400;
  font-size: 14px;
}

.comment {
  font-weight: 400;
  font-size: 12px;
}

.notifications-body {
  .Toastify__toast-icon {
    margin-top: 2px;
  }
}

.view-page-wrapper {
  padding: 2rem;
}

.beta-tag {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(113, 34, 255, 0.2);
  color: rgb(113, 34, 255);
  padding: 1em;
  font-size: 10px;
  z-index: 9999;
  opacity: 1;
}
