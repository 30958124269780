@import '../../style/typography.scss';
@import '../../style/colors';

button.button {
  border-radius: 4px;
  padding: 7px 16px;
  line-height: unset;

  &.table-button {
    padding: 2px 7px;
  }

  @include typo-title-base_medium;

  text-transform: unset;
  box-shadow: unset;

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    box-shadow: none;
  }

  &.primary {
    color: $neutral-0;
    background: $primary-40;
    border: 1px solid $primary-40;

    &:disabled {
      background: $primary-10;
      border: 1px solid $primary-10;
    }

    svg {
      fill: $neutral-0;
    }
  }

  &.secondary {
    background: $neutral-0;
    border: 1px solid $primary-40;
    color: $primary-40;

    &:disabled {
      border: 1px solid $primary-10;
      color: $primary-10;
    }

    svg {
      fill: $primary-40;
    }
  }

  &.primary,
  &.secondary {
    &:hover {
      background: $primary-20;
      border: 1px solid $primary-20;
      color: $neutral-0;
    }
  }
}

.action-button__icon {
  svg {
    margin-right: 8px;
    height: 24px;
  }
}

.action-button__loader {
  svg {
    height: 24px;
  }
}
