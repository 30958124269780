@import '../../../variables.scss';

.offersUITableContent {
  &.MuiBox-root {
    padding: 0 64px;
  }
  .MuiDataGrid-root {
    margin-top: 24px;
  }
  .MuiDataGrid-columnHeaders {
    border-bottom-color: $neutral-30 !important;
  }
  .offersUITableHeaderCell {
    font-size: 12px;
    color: $neutral-70;
    .MuiDataGrid-columnHeaderTitle {
      font-weight: 400 !important;
    }
  }
  .offersUITableCell {
    color: $neutral-70;
  }
}

.viewHeader {
  .base-state {
    padding: 0 64px 14px 64px;
  }
}
