.search-bar {
  .MuiInputBase-root,
  .MuiFormControl-root {
    height: 40px;
    border-radius: 7px;
    margin-right: 16px;
  }
  .MuiFormControl-root {
    width: 300px;
  }
  .MuiInputBase-root {
    min-width: 120px;
  }
}
