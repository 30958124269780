@import '../../variables.scss';

#blockOverlay {
  display: none;
}

@media (max-width: 1024px) {
  #blockOverlay {
    position: fixed;
    overflow-y: hidden;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3000;
    text-align: center;
    background-color: rgba($color: #000000, $alpha: 0.8);

    img {
      height: 58px;
    }
    h2 {
      color: white;
      font-size: 16px;
      font-weight: 500;
    }
    p {
      margin: 0;
      color: white;
      font-size: 16px;
      font-weight: 200;
      width: 400px;
    }
  }
}
