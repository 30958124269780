.addLanguageForm {
  &__input {
    width: 250px;
    margin-right: 12px;

    & .MuiInputBase-input::placeholder {
      color: #000000;
      opacity: 1;
    }

    .formContent-input-inputField {
      & input {
        padding: 6px 8px;
      }
    }
  }
  &__select {
    min-width: 250px;
    margin-right: 12px;

    .formContent-select-fieldTitle {
      h3 {
        font-weight: 500;
      }
    }

    .formContent-select-selectField {
      & .MuiSelect-select {
        padding: 6px 8px;
      }
    }
  }

  & button {
    margin-top: 18px;
    padding: 5px 16px;
  }
}
