@import '../../../variables.scss';

.sidebar-profile {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0.75em;
  &:hover {
    cursor: pointer;
  }
}

hr {
  height: 0;
  border: 0;
  border-top: 1px solid $lightGray;
  width: 95%;
  transition: 0.18s all;
  transition-delay: 0.4s;
}

p,
.arrow-down-button:not(.closed) {
  transition: 0.18s all;
  transition-delay: 0.4s;
}

.closed {
  hr,
  p,
  .arrow-down-button {
    opacity: 0;
    transition-delay: 0s;
  }
}

.sidebar-profile-actions {
  padding: 0;
  margin: 0;
  list-style-type: none;
  li {
    margin: 16px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: $darkGray;
    padding: 12px;
    height: 36px;
    gap: 0.5em;
    border-radius: 0.5em;
    &:hover:not(.disabled) {
      cursor: pointer;
      background: lighten($lightGray, 20%);
    }
    h3 {
      font-family: $font-family;
      font-weight: 500;
      font-size: 16px;
    }
    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
  }
}

.sidebar-profile-userName {
  max-width: 150px;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.sidebar-profile-userEmail {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;
}
.sidebar-profile-textBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 1em);
  margin-right: 12px;
  color: var(--primary-40, #6109ff);
}
.sidebar-profile-subText {
  display: flex;
  height: 32px;
  padding: 8px 7px 9px 7px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 2px;
  background: var(--primary-1, #e3d3ff);
}

.role-tag {
  display: inline-block;
  margin-top: 2em;
  background: rgba(113, 34, 255, 0.2);
  padding: 0.5em 1em;
  color: rgb(162, 111, 255);
  font-size: 10px;
  border-radius: 0.5em;
  max-width: 100%;
  font-family: $font-family;
}
