@import '../../variables.scss';

#productForm {
  .inputBox {
    h3 {
      margin-bottom: 24px;
    }

    .MuiInputBase-root {
      width: 316px;
      height: 48px;
      padding: 0 16px;
      box-sizing: border-box;

      input {
        padding: 0;
      }
    }
  }
}

#mui-component-select-images {
  display: flex;
  align-items: center;
}
