@import '../../variables.scss';

.acSeparator {
  height: 0;
  border: 0;
  border-top: 0.5px solid $lightGray;
  width: 100%;
  &.dotted {
    border-style: dotted;
  }
}
