@import '../../variables.scss';

#sideNav {
  width: $sidebarWidth;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $white;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid $lightGray;
  transition: all 0.5s 0.05s;

  .arrow-container {
    cursor: pointer;
    position: absolute;
    right: -12px;
    top: 120px;
    z-index: 1000;
  }

  .arrow {
    transition:
      width 0.5s,
      background-color 0.18s;
    color: $darkGray;
    background-color: $white;
    border: 0.5px solid $darkGray;
    border-radius: 50%;
    padding: 4px;
    &:hover {
      background-color: #e4d3ff;
      color: $primary;
    }
  }

  .logoContainer,
  h2,
  h3,
  span,
  .sidebarItem > div:nth-child(2) {
    transition: opacity 0.3s 0.4s;
  }

  &.closed {
    width: $sidebarCollapsedWidth;

    .arrow {
      transform: rotate(180deg);
    }

    .logoContainer,
    h2,
    h3,
    span,
    .sidebarItem > *:not(svg) {
      transition-delay: 0s;
      opacity: 0;
    }

    ul.navGroup {
      > span {
        opacity: 1;
        svg,
        div:first-of-type {
          opacity: 1;
        }
      }
    }

    .sidebarItem-wrapper {
      opacity: 1;
    }

    ul.navGroup {
      &.nav-footer {
        &::after {
          border-top: 0;
        }
        &::before {
          border-top: 0;
        }
      }
    }
  }

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;

    img {
      height: 40px;
    }

    span {
      display: inline-block;
      margin-top: 0.5em;
      color: lighten($primary, 15%);
      font-size: 10px;
    }
  }

  ul.navGroup {
    &:first-of-type {
      margin-top: 12px;
    }
    margin: 20px 0 0 0;
    padding: 0 5px;
    width: 100%;
    &.navGroup-subMenu {
      margin-top: 0;
    }
    h2 {
      color: $darkGray;
      border-bottom: 1px solid $lightGray;
      font-size: 10px;
      padding-bottom: 12px;
      padding-left: 8px;
    }
    &.nav-footer {
      position: absolute;
      bottom: 2em;
      &::after {
        content: '';
        width: 90%;
        border-top: 1px solid #bebebe;
        z-index: 100;
        left: 0.75em;
        position: absolute;
        top: 0;
      }
      &::before {
        content: '';
        width: 90%;
        border-top: 1px solid #bebebe;
        z-index: 100;
        left: 0.75em;
        position: absolute;
        bottom: -0.75em;
      }
    }
    &.navGroup-main {
      margin-top: 0;
      h2 {
        display: none;
      }
    }

    &:not(:has(li)) {
      display: none;
    }
  }

  .nav-profile-footer {
    position: absolute;
    bottom: 0;
    width: 100%;

    hr {
      @apply my-2 mx-auto;
    }
  }
  .back-button {
    padding: 0 8px 0 0;
    box-sizing: content-box;
    color: black;
    & svg {
      margin-left: 20px;
    }
    &:hover {
      background: none;
    }
  }
}

.api-env {
  font-weight: bold;
  color: red !important;
  font-size: 12px !important;
  text-transform: uppercase;
  animation: 1s ease-in-out infinite condemned_blink_effect;
  display: block !important;
  cursor: pointer;
  > span {
    font-size: 8px !important;
    text-align: center;
  }
}

@keyframes condemned_blink_effect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.store-logo-open {
  max-height: 200px;
  transition: 0.5s all;
  * {
    transition: 0.5s opacity;
    transition-delay: 0.4s;
    opacity: 1;
  }
  img {
    max-width: 35px;
    max-height: 35px;
  }
  h4 {
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1em;
  }
}

.store-logo-closed {
  max-height: 0;
  transition: 0.5s all;
  * {
    opacity: 0;
    transition-delay: 0;
  }
  img {
    max-width: 35px;
    max-height: 35px;
  }
  h4 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1em;
  }
}

.project-logo {
  width: 30px;
  height: 30px;
  margin-right: 12px;
  object-fit: contain;
}
