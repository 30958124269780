@import '../../variables.scss';

.gallery {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.text {
  padding-top: 1em;
}

.header-buttons {
  display: flex;
  gap: 15px;
}

.search-bar {
  margin-top: 24px;
}

.upload-image {
  position: relative;

  &__btn {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(calc(-50% + 10px));
  }
}
