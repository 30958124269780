@import '../../../variables';

.sidebarItem {
  display: flex;
  align-items: center;
  height: 36px;
  width: 100%;
  padding: 0 12px;
  border-radius: 7px;
  color: $darkGray;
  text-decoration: none;
  margin-top: 8px;
  cursor: pointer;

  > div {
    display: flex;
  }

  svg {
    margin-right: 8px;
  }

  span {
    color: $primary;
    border-radius: 4px;
    padding: 4px;
    background-color: rgba($color: $primary, $alpha: 0.2);
    margin-left: 8px;
    font-size: 10px;
  }

  h3 {
    color: $darkGray;
    font-weight: 500;
  }

  &.active {
    color: $primary;
    background-color: rgba($color: $primary, $alpha: 0.2);
    svg {
      fill: $primary;
    }
    &:hover {
      background-color: rgba($color: $primary, $alpha: 0.2);
    }
  }
  &:hover {
    background-color: $hover;
  }
  &.disabled {
    opacity: 0.5;
    h3 {
      color: $darkGray;
      font-weight: 500;
    }
    &:hover {
      background-color: unset;
      cursor: not-allowed;
    }
  }

  &.featureFlag {
    position: relative;
    &::after {
      content: '';
      color: $primary;
      border-radius: 50%;
      background-color: red;
      margin-left: 8px;
      font-size: 10px;
      position: absolute;
      width: 4px;
      height: 4px;
      top: 0.5em;
      left: 0;
    }
  }
}
