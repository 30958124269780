@mixin typo-title-xs {
  font-size: 0.75rem;
  font-weight: 400;
}

@mixin typo-title-xs_medium {
  font-size: 0.75rem;
  font-weight: 500;
}

@mixin typo-title-s {
  font-size: 0.875rem;
  font-weight: 400;
}

@mixin typo-title-s_medium {
  font-size: 0.875rem;
  font-weight: 500;
}

@mixin typo-title-base {
  font-size: 1rem;
  font-weight: 500;
}

@mixin typo-title-base_medium {
  font-size: 1rem;
  font-weight: 600;
}

@mixin typo-title-base_medium {
  font-size: 1rem;
  font-weight: 600;
}

@mixin typo-title-medium {
  font-size: 18px;
  font-weight: 600;
}

@mixin typo-title-large {
  font-size: 24px;
  font-weight: 600;
}

@mixin typo-title-large-bolder {
  font-size: 24px;
  font-weight: 700;
}

@mixin typo-title-xlarge {
  font-size: 32px;
  font-weight: 600;
}

@mixin typo-title-xxlarge {
  font-size: 40px;
  font-weight: 700;
}

// -----------------

@mixin typo-label-capital {
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

@mixin typo-body-small {
  font-size: 12px;
  font-weight: 400;
}

@mixin typo-body-small-bolder {
  font-size: 12px;
  font-weight: 600;
}

@mixin typo-body-base {
  font-size: 14px;
  font-weight: 400;
}

@mixin typo-body-medium {
  font-size: 16px;
  font-weight: 400;
}

@mixin typo-body-medium-bolder {
  font-size: 16px;
  font-weight: 600;
}

@mixin typo-body-large {
  font-size: 20px;
  font-weight: 400;
}
