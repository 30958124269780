@import '../../variables.scss';

.triggers-action {
  background: #e3d3ff;
  color: $primary;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
  width: 51px;
  text-align: center;
}
