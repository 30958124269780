button.google-login-button {
  --google-grey: #d4d8df;
  transition: background-color 0.5s ease-in-out;

  &:hover {
    background-color: (var(--google-grey)) !important;
  }
}

.login-divider {
  margin: 25px 0;
  &__line {
    width: 100%;
  }

  &__text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    padding: 0 8px;
    background-color: white;
  }
}