.date-picker-overlay-bg {
  display: flex;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.date-picker-title {
  width: 626px;
  background: #fff;
  padding: 1em;
  font-size: 1.2em;
  color: #000;
}

.date-picker-footer {
  width: 626px;
  background: #fff;
  padding: 1em;
  font-size: 1.2em;
  color: #000;
  display: flex;
  justify-content: flex-end;
}
