@import '../../variables.scss';
@import '../../style/typography';

.acStatbox {
  display: flex;
  height: 180px;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
  color: $text;

  border-radius: 4px;
  border: 1px solid #cacbd4;
  background: $white;

  .stat-name {
    @include typo-title-medium;
  }
  .stat-value {
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 0.5em;
    span {
      @include typo-body-large;
    }
  }
  .stat-change-period {
    .up {
      fill: $success;
      color: $success;
    }
    .down {
      fill: $error;
      color: $error;
    }
    span {
      @include typo-body-base;
    }
  }
}
