// table style
.MuiDataGrid-root {
  border: 0 !important;
  margin-top: 30px;
}

// header column separator
.MuiDataGrid-columnSeparator {
  visibility: hidden !important;
}

// header row
.css-f3jnds-MuiDataGrid-columnHeaders,
.css-r11z79-MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: 1px solid #cacbd4 !important;
}

// rows
.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  font-weight: 500 !important;
}
