@import '../../style/colors';
@import '../../style/typography';
@import '../../variables.scss';

.status-label {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  font-size: 16px;
  border-radius: 4px;
  background-color: $neutral-30;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 4px;
  }

  &__text {
    @include typo-body-base;
  }

  &.success {
    background-color: $func-success;
  }

  &.failed {
    background-color: $func-error;
  }

  &.refunded {
    background-color: $user-light-blue;
  }

  &.pending {
    background-color: $user-orange;
  }

  &.active {
    background: $active;
  }

  &.inactive {
    background: $inactive;
  }
}
