@import '../../variables.scss';

.firstActionModal {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid $lightGray;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $backgroundColor;

  h3 {
    font-size: 18px;
    font-weight: 700;
  }

  p {
    max-width: 360px;
    font-size: 14px;
    font-weight: 400;
    // margin: 12px 0 24px 0;
    margin-bottom: 0;
  }
}
