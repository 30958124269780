.ac-avatar--super-admin {
  background: linear-gradient(
    to right,
    #bf953f,
    #fcf6ba,
    #b38728,
    #fbf5b7,
    #aa771c
  );
  border-radius: 50%;
  padding: 2px;
  display: inline-block;
}
