@import '../../variables.scss';

.acChartBox {
  height: auto;
  max-height: 380px;
  background-color: white;
  padding: 1em;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #cacbd4;

  .chart {
    height: 250px;
    width: 100%;
    display: flex;
  }
  .stat-name {
    padding-bottom: 1em;
    flex: 0.8;
    span {
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 120%;
      color: $text;
    }
  }
}
