@import '../../../../style/typography';
@import '../../../../variables.scss';

.sub-index-card {
  display: flex;
  padding: 15px 15px 5px;
  justify-content: space-between;
  color: $text;
  border-radius: 4px;
  border: 1px solid #cacbd4;
  background: $white;
  min-width: 532px;
  margin: 10px 0;
  width: 530px !important;
}

.custom-data-grid {
  .MuiDataGrid-root {
    margin-top: 0 !important;
  }
  .MuiDataGrid-cell,
  .MuiDataGrid-columnHeader {
    border: 1px solid #ddd !important;
    justify-content: space-between !important;
  }
  .MuiDataGrid-columnHeaders {
    width: 500px !important;
    border: 1px solid #ddd !important;
  }
  .MuiDataGrid-columnHeaderTitle {
    font-weight: 400 !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root {
    width: 230px !important;
  }
}

.add-product-button {
  font-weight: 400 !important;
  text-transform: none !important;
  &.Mui-disabled {
    color: #6109ff !important;
  }
}

.card-actions {
  display: flex;
  gap: 8px;
}
