@import '../../variables.scss';

.view {
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  color: black;
}

.blank-image,
.blank-logo {
  width: 150px;
  height: 150px;
  background: $lightGray;
  margin-top: 1em;
  border-radius: 0.5em;
}

.blank-logo {
  width: 350px;
}

.MuiTab-root {
  text-transform: none !important;
}

.main-grid {
  max-width: 650px;
}

.iphone-mock-wrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;

  .button-container {
    position: relative;

    .dashboard-preview {
      width: max-content;
    }
  }
}

.iphone-mock {
  position: sticky;
  top: 1rem;
  overflow: scroll;
  width: 375px;
  height: 812px;
  padding-top: 80px;
  background: #322566;
  border-radius: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 1em;

  img.storeLogo {
    margin-bottom: 40px;
    height: 120px;
    &.big {
      height: 144px;
    }
  }
}

.store-preview-wrapper {
  position: sticky;
  top: 1rem;
}

.bundles-wrapper {
  width: 100%;
  position: relative;
  min-height: 150px;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1250px;
}

.bundles-wrapper:not(.bundles_layout__two-in-row *) {
  padding: 16px;
  gap: 2px;
}

.special-offer {
  width: 100%;
}

.store-theme-sections {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 100%;
  .section-bagelcode {
    margin-bottom: 6px;
    justify-content: center;
  }
  .bundles_layout {
    margin-bottom: 20px;
  }
}

.offers-form-sections {
  .section-bagelcode {
    margin-bottom: 12px;
  }
}

.storeTheme-form {
  padding: 0 24px 24px 24px;
  height: 100vh;

  .tabPanel-tabs {
    margin-bottom: 36px;
  }
}
