@import '../../variables.scss';

.quill {
  .ql-container {
    font-size: 16px;
  }

  .ql-size-small {
    font-size: 14px;
  }

  .ql-size-large {
    font-size: 18px;
  }

  .ql-size-huge {
    font-size: 20px;
  }

  .ql-container {
    height: 100px;
  }
}
