.financial-reports-table,
.payouts-table {
  & .firstActionModal {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  & .MuiDataGrid-root {
    margin-top: 0;
  }
}
.disclaimer {
  border-top: 1px solid #e0e0e0;
  color: black;
  padding: 12px;
  font-size: 14px;
}
.project-name-data {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}
