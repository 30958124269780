//Primary Colors
$primary-0: #e3d3ff;
$primary-10: #c5a4ff;
$primary-20: #8e4eff;
$primary-30: #7122ff;
$primary-40: #6109ff;
$primary-50: #4d00d9;
$primary-60: #432e98;
$primary-70: #332667;

//Neutral Colors
$neutral-0: #ffffff;
$neutral-10: #f4f4f8;
$neutral-20: #e3e4eb;
$neutral-30: #cacbd4;
$neutral-40: #a8a9b2;
$neutral-50: #717188;
$neutral-60: #323241;
$neutral-70: #000000;

//Functional Colors
$func-success: #51ca6b;
$func-error: #eb7373;

//User Colors
$user-orange: #f99e6b;
$user-light-blue: #779cef;
$user-solid-blue: #0083d6;
$user-green: #279e82;
