@import '../../../../variables.scss';

.languagesList {
  padding: 16px 16px 5px 16px;

  .formContent-card-title {
    & .sectionTitle {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 16px;
    }
  }

  .formContent-card-description {
    & .languagesListdDescription {
      margin-bottom: 16px;
      color: #000000;
    }
  }

  .languagesListTable {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 120px;

    & .MuiDataGrid-root {
      margin-top: 10px;
    }

    & .MuiDataGrid-columnHeaderTitle {
      font-size: 12px;
      font-weight: 500;
    }

    & .MuiDataGrid-cell,
    & .MuiDataGrid-columnHeader {
      padding-left: 0;
    }

    & .firstActionModalContainer {
      max-height: 200px;
    }
  }
}

.countryItem {
  & p {
    color: #717188;
  }
}

.countryFlag {
  width: 16px;
  height: 16px;
  object-fit: cover;
  border-radius: 50%;
}

.countryName {
  white-space: pre-wrap;
}

.deleteLanguageModal {
  h2 {
    padding-bottom: 8px;
  }
  & .MuiDialogContentText-root {
    padding-top: 16px;
  }
}
