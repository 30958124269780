@import '../../../../variables.scss';

.localizationCSVCard {
  padding: 16px;
  margin-top: 23px;

  .formContent-card-title {
    & .localizationCSVCardTitle {
      font-size: 18px;
      font-weight: 700;
    }
  }

  .formContent-card-description {
    & .localizationCSVCardDescription {
      color: #000000;
    }
  }
}
