.ac-phone-input-box {
  .ac-phone-inputs {
    display: flex;

    & > *:first-child {
      margin-right: 8px;
      width: 30%;
    }
  }

  .MuiInputBase-root:not(.MuiInputBase-multiline) {
    width: 100%;
    box-sizing: border-box;
  }
}
