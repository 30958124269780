@import '../../variables.scss';

#statistics {
  .statBoxes {
    padding-bottom: 1em;
    &.graphs {
      margin-top: -14px;
    }
  }
  h4 {
    margin-top: 16px;
    &.range {
      margin-bottom: 0;
      color: $primary;
      border: 1px solid $lightGray;
      border-radius: 7px;
      width: fit-content;
      font-weight: 400;
      padding: 8px 12px;
    }
  }
}
