@import '../../variables.scss';

.MuiDialog-paper {
  padding: 20px;
  box-sizing: border-box;

  button.primary.MuiButton-containedError {
    background-color: $error;
    border: 1px solid $error;
    color: $white;

    &:hover {
      background: rgba(235, 115, 115, 0.9);
    }
  }
  button.secondary.MuiButton-outlinedSecondary {
    border: 1px solid $neutral-70;
    color: $neutral-70;

    &:hover {
      background: $neutral-10;
    }
  }
  .MuiButton-containedPrimary {
    background-color: $primary;
  }
  .MuiDialogContent-root {
    padding: 0 0 12px 0;
  }
  .css-hlj6pa-MuiDialogActions-root {
    padding: 0;
  }

  .dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0 0 8px 0;
  }

  h2 {
    font-weight: bold;
    text-transform: none;
    padding: 0;
  }

  p {
    max-width: 350px;
  }
}

.download-template {
  display: flex;
  margin-bottom: 16px;
  gap: 4px;
  cursor: pointer;
}
